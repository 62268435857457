import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useParameterList() {
  // Use toast
  const toast = useToast();

  const refParameterListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "description",
      label: VueI18n.t("description"),
      sortable: false,
    },
    {
      key: "value",
      label: VueI18n.t("value"),
      sortable: false,
    },

    // { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refParameterListTable.value
      ? refParameterListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refParameterListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchParameters = async (ctx, callback) => {
    var Parameter = await store
      .dispatch(
        "definitionsModule/fetchSystemParameters",
        store.state.app.selectedPlantId
      )

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("Parameters") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (searchQuery.value != "")
      Parameter = Parameter.filter((x) =>
        x.description
          .toLowerCase()
          .startsWith(searchQuery.value.toLocaleLowerCase())
      );
    return Parameter;
  };
  const deleteParameter = (ctx) => {
    store
      .dispatch("definitionsModule/deleteSystemParameter", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("Parameter") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("Parameter") }),
          },
        });
      });
  };

  // *===============================================---*
  // *------------------- UI ---------------------------*
  // *===============================================---*

  return {
    fetchParameters,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refParameterListTable,
    deleteParameter,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
