<template>
  <div><parameter-list /></div>
</template>

<script>
import ParameterList from "./components/ParameterList/ParameterList.vue";
export default {
  components: { ParameterList },
};
</script>

<style></style>
