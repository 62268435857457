<template>
  <b-modal
    id="edit-parameter-sidebar"
    centered
    :visible="isEditParameterSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="$t('updateParameter')"
    @ok="onSubmit(blankParameterData)"
    @hidden="formValidation(resetblankparameter).resetForm"
    @change="(val) => changed(val)"
  >
    <!--/ media -->
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankparameter
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <validation-provider
          #default="validationContext"
          rules=""
          name="description"
          class="w-100"
        >
          <b-form-group :label="$t('description')" label-for="description">
            <b-form-input
              id="description"
              v-model="blankParameterData.description"
              autofocus
              :state="
                formValidation(resetblankparameter).getValidationState(
                  validationContext
                )
              "
              trim
              disabled
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          rules=""
          name="value"
          class="w-100"
        >
          <b-form-group :label="$t('value')" label-for="value">
            <b-form-input
              id="value"
              v-model="blankParameterData.value"
              autofocus
              :state="
                formValidation(resetblankparameter).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditParameterSidebarActive",
    event: "update:is-edit-parameter-sidebar-active",
  },
  props: {
    isEditParameterSidebarActive: {
      type: Boolean,
      required: true,
    },
    parameter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      irk: [],
      required,
      blankParameterData: {
        ...this.parameter,
        plantid: this.$store.state.app.selectedPlantId,
      },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  async mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.resetblankparameter();

      this.$emit("update:is-edit-parameter-sidebar-active", val);
    },
    onSubmit() {
      this.blankParameterData.deger = this.blankParameterData.value;
      store
        .dispatch(
          "definitionsModule/updateSystemParameter",
          this.blankParameterData
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-parameter-sidebar-active", false);
        });
    },
    async resetblankparameter() {
      this.blankParameterData = {
        ...this.parameter,
        plantid: this.$store.state.app.selectedPlantId,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-parameter-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
